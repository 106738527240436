.default-navbar {
  color: #191818;
}

.navbar.scroll {
  background: #ffffff;
  color: #006677;
}

.navbar.second-scroll {
  background: #ffffff;
  color: #191818;
}

.navbar .toggle-nav {
  color: #006677 !important;
  background: transparent;
  border: none;
}
.navbar .toggle-nav,
.modal .toggle-nav {
  color: white;
  background: transparent;
  border: none;
}

.nav-link-text {
  color: #191818;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  cursor: pointer;
}

.navbar a,
.modal a {
  text-decoration: none;
}

.navbar.secondary {
  background: #ffffff;
}

.modal .modal-dialog {
  margin: 0;
  height: 100%;
}

.modal .modal-content {
  height: 100%;
  background: #006677;
}

.text-nav-secondary {
  color: #262626 !important;
}

span.active {
  border-bottom: #00688180 solid 4px;
}

@media screen and (min-width: 992px) {
  .navbar .toggle-nav {
    display: none;
  }
}

#footer {
  background: #006881;
}

#footer .row {
  padding: 80px 80px 40px 80px;
  color: #ffffff;
}

#footer h5 {
  font-weight: 800;
}

#footer p {
  font-weight: 100;
}

#footer p span {
  font-weight: 500;
  display: inline;
}

@media (max-width: 768px) {
  #footer .row {
    padding: 35px 35px 40px 35px;
    text-align: center;
  }

  #footer p span {
    display: block;
  }
}

.spinner-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner-load1 {
  width: 80px;
  height: 80px;
  color: #c7ece4;
}

.spinner-load2 {
  width: 60px;
  height: 60px;
  color: #9cded2;
  position: absolute;
}

.spinner-load3 {
  width: 40px;
  height: 40px;
  color: #74cfc0;
  position: absolute;
}

.td-table {
  color: #858585 !important;
  font-size: 14px;
  vertical-align: middle;
}

.td-expanded-table {
  color: #fff !important;
  font-weight: 500;
  font-size: 14px;
  vertical-align: middle;
  background-color: #106c85 !important;
}

.th-table {
  line-height: 28px !important;
  color: #646464 !important;
  font-size: 16px !important;
  vertical-align: middle !important;
}

.dec-claim {
  /* background: #ffbb57; */
  background: linear-gradient(180deg, #bb1b26, rgb(172 63 63 / 0%) 256.98%);
  border-radius: 15px;
  color: white;
  padding: 5px 10px;
  width: 100%;
  margin-bottom: 7px;
  cursor: pointer;
}

.dec-detail {
  background: linear-gradient(180deg, #006881, rgba(67, 186, 148, 0) 136.98%);
  border-radius: 15px;
  color: white;
  padding: 5px 10px;
  width: 100%;
  margin-bottom: 7px;
  cursor: pointer;
}

.action-detail {
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  /* verticalalign: middle; */
}

.action-detail-claim {
  border-radius: 15px;
  color: white;
  padding: 5px 10px;
  width: 100%;
  margin-bottom: 7px;
  cursor: pointer;
  background: #ffbb57;
}
.action-detail-claim:hover {
  background: #ffc062;
}

.action-detail-claim-disabled {
  border-radius: 15px;
  color: white;
  padding: 5px 10px;
  width: 100%;
  margin-bottom: 7px;
  cursor: default;
  background: #bc8330;
}

.loading-expanded {
  height: 10px;
  width: 10px;
  color: #fff;
  margin-right: 5px;
}

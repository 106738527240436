.accordion-button {
  background-color: #ffffff !important;
}

.card-wrapper {
  border: none;
  border-radius: 13px;
  box-shadow: #e0e0ec66 0px 0px 10px 10px;
  min-height: 400px;
}

.card-main-header {
  border: none;
  background: transparent;
  font-size: 18px;
  font-weight: 800;
  color: #646464;
}

.main-card .title {
  color: #646464;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 500;
}

.main-card .value {
  color: #646464;
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 800;
}

.main-card .value-left {
  color: #646464;
  font-size: 16px;
  margin-bottom: 14px;
  font-weight: 800;
}

.main-card .insured {
  background: #f5f5f7;
  color: #646464;
  font-size: 20px;
  display: flex;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 10px;
  font-weight: 800;
}

.title-header-right {
  color: #646464;
  font-weight: 800;
}

.title-header-left {
  color: #646464;
  font-weight: 500;
}

.back-button {
  background: linear-gradient(180deg, #006881, rgba(67, 186, 148, 0) 136.98%);
  border: none !important;
  min-width: 130px !important;
  padding: 8px 25px !important;
  font-weight: 800 !important;
  font-size: 14px !important;
  border-radius: 20px !important;
}

/* FORM SUBMISSION */
.form-submission .form-control {
  border: 1px solid #bcbcbc;
  width: 100%;
  background: #ffffff;
  border-radius: 5px;
  padding: 15px;
}

.form-submission .form-select {
  border: 1px solid #bcbcbc;
  width: 100%;
  border-radius: 5px;
  padding: 15px;
}

.form-submission .form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-size: 16px;
  color: #939393;
  text-align: left;
}

.form-check-input:checked {
  background-color: #016981;
  border-color: #016981;
}

.toast {
  position: fixed;
  right: 20px;
  top: 100px;
}

.download-doc {
  box-shadow: #e0e0ec66 0px 0px 10px 10px;
  background-color: #43ba94;
  border-radius: 13px;
  min-width: 100px;
  padding: 2px 15px;
  text-decoration: none;
  font-size: 14px;
  color: #fff;
  border: none;
}

.react-bs-table-sizePerPage-dropdown #pageDropDown {
  font-size: 12px;
  background: transparent;
  color: #000;
  border: 1px solid #067;
}

.react-bs-table-sizePerPage-dropdown .dropdown-menu.show {
  font-size: 12px;
  min-width: auto;
}

.form-control {
  border: none;
  width: 70%;
  border-radius: 10px;
  background: #eaeaea;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-size: 14px;
  color: #939393;
  text-align: center;
}

.search-btn button {
  background: linear-gradient(180deg, #006881, rgba(67, 186, 148, 0) 136.98%);
  border: none !important;
  min-width: 130px !important;
  padding: 4px 20px !important;
  font-weight: 800 !important;
  border-radius: 20px !important;
}

select {
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") !important;
  background-repeat: no-repeat !important;
  background-position-x: 98% !important;
  background-position-y: 5px !important;
}

.accordion-button {
  background-color: #ffffff !important;
}

.card-wrapper {
  border: none;
  border-radius: 13px;
  box-shadow: #e0e0ec66 0px 0px 10px 10px;
  min-height: 500px;
}

.card-main-header {
  border: none;
  background: transparent;
  font-size: 18px;
  font-weight: 800;
  color: #646464;
}

.main-card .title {
  color: #646464;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 500;
}

.main-card .value {
  color: #646464;
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 800;
}

.main-card .value-left {
  color: #646464;
  font-size: 16px;
  margin-bottom: 14px;
  font-weight: 800;
}

.main-card .insured {
  background: #f5f5f7;
  color: #646464;
  font-size: 20px;
  display: flex;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 10px;
  font-weight: 800;
}

.title-header-right {
  color: #646464;
  font-weight: 800;
}

.title-header-left {
  color: #646464;
  font-weight: 500;
}

.claim-btn {
  cursor: pointer;
  background: #f178b6;
  color: #fff;
  width: 100%;
  padding: 10px 15px;
  border-radius: 25px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 20px;
}

.back-button {
  background: linear-gradient(180deg, #006881, rgba(67, 186, 148, 0) 136.98%);
  border: none !important;
  min-width: 130px !important;
  padding: 4px 30px !important;
  font-weight: 800 !important;
  border-radius: 20px !important;
}

.badge-table {
  background: #006881 !important;
  color: #fff !important;
}
